.main3 {
  width: 600px;
  height: 860px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../img/bg3.png);
  background-position: center 65%;
  background-repeat: no-repeat;
  background-color: white;
  margin-top: 50px;
}

.download-block {
  margin-top: 23px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  max-width: 538px;
  max-height: 467px;
}

.download-block img {
  max-width: 538px;
  max-height: 467px;
}

.download-block:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.i-paid-block3 {
  margin-top: 15px;
}

.input-hidden{
  display: none;
}
