.main2 {
  width: 600px;
  height: 860px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../img/bg2.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: white;
  margin-top: 50px;
}

.main2-container {
  width: 100%;
  height: 100%;
}
.main2-content {
  padding: 28px 31px;
}

.alpha {
  color: #151944;
  font-size: 30px;
  font-weight: 700;
  margin-top: 53px;
}

.line {
  margin-top: 0px;
}

.price-on {
  color: #151944;
  font-size: 25px;
  font-weight: 700;
  margin-top: 47px;
}

.sbp-pay-block {
  margin-top: 74px;
}

.sbp-card-number {
  padding-left: 15px;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 30px;
  background-color: white;
  border-radius: 15px;
  border: 1.5px solid #e5e6ea;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sbp-card-number:hover {
  transition: 0.3s;
  opacity: 0.8;
}
.left-sbp-card-nubmber {
  display: flex;
  align-items: center;
}
.card-id {
  padding-left: 12px;
  padding-right: 9px;
  font-weight: 600;
  color: #151944;
  font-size: 20px;
}
.number-card-text {
  color: #151944;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 600;
  white-space: pre-wrap;
  padding-left: 12px;
  padding-right: 9px;
}

.comment-id {
  padding-left: 12px;
  padding-right: 9px;
  font-weight: 600;
  color: #151944;
  font-size: 20px;
}

.spb-comment {
  margin-top: 19px;
}

.i-paid {
  background-color: #5465ff;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 17px;
  padding-bottom: 17px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 20px;
  border: white 2px solid;
  cursor: pointer;
}

.i-paid-block {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-block-page2 {
  margin-top: 133px;
}

.copy-mark {
  position: absolute;
  left: 480px;
  bottom: 10px;
}
