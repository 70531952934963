@font-face {
  font-family: "SuisseIntlRegular";
  src: url("../fonts/suisse-intl-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body{
  background-color: #e7e7e7;
}
.body-container {
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  /*background-color: gray;*/
  font-family: "SuisseIntlRegular", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.btn,
a,
button {
  transition: 0.3s;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
  background-color: unset;
  padding: 0;
}
.btn:hover,
a:hover,
button:hover {
  transition: 0.3s;
  opacity: 0.8;
}

p,
h1,
h2,
h3,
h4,
li,
ul,
span {
  white-space: nowrap;
  margin: 0;
  font-optical-sizing: auto;
  font-style: normal;
  font-family: "SuisseIntlRegular", sans-serif;
}

.main {
  width: 600px;
  height: 860px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../img/bg1.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: white;
  margin-top: 50px;
}

.main-container {
  width: 100%;
  height: 100%;
}

.logo-cont {
  display: flex;
  align-items: center;
}

.logo-cont p {
  padding-left: 12px;
  font-size: 25px;
  color: #151944;
  font-weight: 700;
}

.buy-price {
  background-color: #eef0ff;
  border-radius: 15px;
  padding: 15px;
}

.price {
  color: #5465ff;
  font-size: 30px;
  font-weight: 600;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chose-paymethod {
  margin-top: 51px;
  color: #151944;
  font-size: 30px;
  font-weight: 600;
  box-sizing: border-box;
  text-align: center;
  white-space: break-spaces;
}
p{
  white-space: break-spaces;
}
.main-content {
  padding: 28px 31px;
}

.method-block {
  margin-top: 156px;
}
.top-method-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ru-card {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 19px;
  padding-right: 30px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1.5px solid #e5e6ea;
}
.ua-card {
  padding-right: 21px;
}
.ru-line {
  display: flex;
  align-items: center;
  justify-content: center;
}
.method-name {
  color: #151944;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 20px;
  font-weight: 500;
}
.percent {
  font-size: 18px;
  color: #151944;
  opacity: 0.5;
  font-weight: 500;
}

.spb {
}

.spb-line {
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 14px;
  border-radius: 15px;
  padding-top: 28px;
  padding-bottom: 29px;
  padding-left: 19px;
  border: 1.5px solid #e5e6ea;
}

.timer-block {
  margin-top: 253px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  max-width: 252px;
}

.timer-content img {
  padding-right: 4px;
}

.timer-content p {
  color: #5465ff;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 625px) {
  .main {
    width: 340px;
    border-radius: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .main-content {
    padding: 14px 16px;
  }
  .price {
    font-size: 18px;
  }
  .logo-cont img {
    width: 28px;
    height: 28px;
  }
  .logo-cont p {
    font-size: 20px;
  }
  .chose-paymethod {
    font-size: 18px;
  }
  .ru-card {
  }
  .top-method-block {
    flex-direction: column;
  }
  .ua-card {
    margin-top: 15px;
  }
  .spb-line {
    flex-direction: column;
    justify-content: center;
  }
  .method-name {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .timer-block {
    margin-top: 123px;
  }
  .method-block {
    margin-top: 106px;
  }
  .main2 {
    width: 340px !important;
    border-radius: 20px !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .main2-content {
    padding: 14px 16px !important;
  }
  .alpha {
    font-size: 18px !important;
  }
  .line img {
    width: 320px !important;
  }
  .price-on {
    font-size: 18px !important;
  }
  .sbp-card-number {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-right: 15px !important;
  }
  .left-sbp-card-nubmber img {
    width: 20px !important;
    height: 20px !important;
  }
  .card-id {
    font-size: 15px !important;
  }
  .number-card-text {
    font-size: 15px !important;
  }
  .comment-id {
    font-size: 15px !important;
  }
  .timer-block-page2 {
    margin-top: 213px !important;
  }
  .download-block img {
    width: 310px !important;
  }
  .i-paid-block3 {
    margin-top: 105px !important;
  }
  .main4 {
    width: 340px !important;
    border-radius: 20px !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .main-content {
    padding: 14px 16px;
  }
  .check-pay-text {
    font-size: 25px !important;
  }
  .itcan {
    font-size: 18px !important;
  }
}

#mark, #mark, #fileInput, .invisible-comment{
  display: none
}

.contacts-test {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: center;
}
