body{
  background-color: #e7e7e7;
  --lite-gray-color: #a1a1a1;
  --gray-color: #6b6b6b;
  --green-color: #26880f;
  --blue-color: #209CEE;
  --animation-time: .5s;
  --besier-right-box: cubic-bezier(.4, .4, 0, 1);
  --push_bg-color: #a8a8a8;
  --red-color: #bd4856;
}


.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.pt-10{
  padding-top: 10px;
}
.fs-20{
  font-size: 20px;
}
.fs-22{
  font-size: 22px;
}
.fs-24{
  font-size: 24px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page{
  padding: 40px;
}
hr {
  width: 100%;
  border-top: 1px solid var(--lite-gray-color);
  opacity: .5;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.button-box{
  box-sizing: border-box;
  /*background-color: #ffffff;*/
  margin: 30px;
  padding: 20px;
  font-size: 20px;
  border-radius: 10px;
  white-space: nowrap;
  /*box-shadow: 3px 3px red, -3px -30px 3px red;*/
  background-color: #ffffff;
  color: #4D4B4B;
  border: 1px solid #cecece;
  box-shadow: 2px 2px 2px #cecece;
}

.button-box:hover{
  background-color: #e8e8e8;
}
.button-box:active{
  background-color: #e8e8e8;
  border: none;
  box-shadow: 1px 1px 1px #cecece;
}

span{
  box-sizing: border-box;
  /*padding-right: 20px;*/
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 100px;
}
.box{
  padding: 30px;
  border-radius: 20px;
  background-color: #ffffff;
  max-width: 300px;
  min-width: 300px;
}

.method-name{
  font-weight: bold;
  cursor: default;
}
.method-commission{
  font-size: 14px;
  cursor: default;
}

.center{
  text-align: center;
  align-content: center;
}
.right{
  text-align: right;
  align-content: end;
}
.top-block{
  position:relative;
  top:-20px;
  color: var(--gray-color);
}
.deadline-timer{
  color: var(--lite-gray-color);
  padding: 0;
}
.green{
  color: var(--green-color)
}
.blue{
  color: var(--blue-color) !important;
  cursor: pointer
}
.lite-gray{
  color: var(--lite-gray-color)
}
.logo{
  max-width: 40px;
}
.loader-box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}
.rotate{
  animation: rotation 1.5s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.hidden{
  display: none;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid var(--lite-gray-color);
  min-width: 100%;
  min-height: 100px;
  display: block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 20px;
}


.push {
  box-sizing: border-box;
  display: block;
  position: fixed;
  background-color: var(--push_bg-color);
  color: #000000;
  padding: 7px;
  z-index: 99999;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 0 0 15px 15px;
  animation: push-hide var(--animation-time) forwards var(--besier-right-box);
}


.push.danger {
  background-color: var(--red-color) !important;
}

.push.success {
  /*background-color: var(--green-color) !important;*/
  background-color: #FCD535 !important;
}


/* PUSH */

.push.hide {
  top: -10% !important;
}

.push-show {
  animation: push-show var(--animation-time) forwards var(--besier-right-box);
}

@keyframes push-hide {
  0% {
    top: 0;
  }
  100% {
    top: -10%;
  }
}

@keyframes push-show {
  0% {
    top: -10%;
  }
  100% {
    top: 0;
  }
}

.comment{
  white-space: pre-wrap;
}
