.main4 {
  width: 600px;
  height: 860px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center 65%;
  background-repeat: no-repeat;
  background: unset;
  background-color: white;
  margin-top: 0;
}

.check-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.check-pay-text {
  color: #151944;
  font-size: 30px;
  font-weight: 600;
  margin-top: 54px;
}

.load-block {
  margin-top: 214px;
  margin-bottom: 214px;
}

.loader {
  width: 125px;
  height: 125px;
  border: 6px solid #eef0ff;
  border-top: 6px solid #5465ff;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  box-sizing: border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.itcan {
  color: #151944;
  opacity: 0.5;
  text-align: center;
  font-size: 20px;
}

.cont {
  color: #151944;
  text-align: center;
}

.hidden{
  display: none;
}
