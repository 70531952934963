.body-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main4 {
  width: 600px;
  height: 860px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center 65%;
  background-repeat: no-repeat;
  background: unset;
  background-color: white !important;
}

.notfoundimg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 214px;
  flex-direction: column;
}
.sorryimg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
  flex-direction: column;
}

.notfoundtxt {
  margin-top: 35px;
  color: #5465ff;
  font-size: 20px;
}

.backtomainpage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}



.backtomainpage a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5465ff;
  font-size: 20px;
}

.backtomainpage a img {
  padding-right: 5px;
}

.check-pay {
  margin-top: 54px;
  color: #151944;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.sorry {
  margin-top: 36px;
  color: #151944;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
